import React from 'react';
import {
  Button,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

type V1Data = {
  id?: string;
  hash: string;
  answer: string;
  imageUrl: string;
  createdAt?: Date | Object;
  updatedAt?: Date | Object;
};

interface ProtectedData {
  title: string;
  columns: {
    title: string;
    content:
      | {type: string; label: string; value: string; link?: string}[]
      | string[];
  }[];
}

const ProtectedSection = ({children, protect = true}: {children: React.ReactNode, protect: boolean}) => {
  const [showingLock, setShowingLock] = React.useState(true);
  const [isUnlocked, setIsUnlocked] = React.useState(false);
  const [hash, setHash] = React.useState('');
  const [hiddenData, setHiddenData] = React.useState<ProtectedData>();
  const [imageUrl, setImageUrl] = React.useState('');
  const [userInput, setUserInput] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!showingLock && !isUnlocked) {
      setLoading(true);
      fetch('/api/v1/captcha')
        .then(res => res.json())
        .then((data: V1Data) => {
          setHash(data.hash);
          setImageUrl(data.imageUrl);
          setLoading(false);
        });
    }
  }, [showingLock, isUnlocked]);

  const showCaptcha = () => {
    setShowingLock(false);
  };

  const handleUnlock = () => {
    setLoading(true);
    fetch(`/api/v1/captcha/verify`, {
      method: 'POST',
      body: JSON.stringify({userInput, hash}),
      headers: {'Content-Type': 'application/json'},
    })
      .then(res => res.json())
      .then((data: {verified: boolean}) => {
        if (data.verified) {
          setIsUnlocked(true);
        } else {
          setError('Incorrect input, please try again');
        }
        setLoading(false);
      });
  };

  if (!protect) {
    return <>{children}</>;
  }

  if (showingLock) {
    return (
      <TouchableOpacity onPress={showCaptcha}>
        <Image
          style={{
            aspectRatio: 1,
            resizeMode: 'contain',
            minHeight: 100,
            minWidth: 'auto',
          }}
          resizeMode="contain"
          source={{uri: './assets/lock.png'}}
          alt="lock"
        />
      </TouchableOpacity>
    );
  }

  if (!isUnlocked) {
    if (loading) return <Text>Loading...</Text>;
    if (error) return <Text>{error}</Text>;
    return (
      <View>
        <Image source={{uri: imageUrl}} alt="captcha" />
        <TextInput
          value={userInput}
          onChange={e => setUserInput(e.nativeEvent.text)}
        />
        <Button onPress={handleUnlock} title="Unlock" />
      </View>
    );
  }

  return (
    <>
      {typeof children === 'function'
        ? (children as (arg0?: ProtectedData) => void)(hiddenData)
        : children}
    </>
  );
};

export default ProtectedSection;
