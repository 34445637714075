import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Linking,
  useWindowDimensions,
  ImageBackground,
  Platform,
} from 'react-native';
import AnimatedSection from './AnimatedSection';
import experienceData from './ExperienceData';
import ExperienceItem from './ExperienceItem';
import useFontSize, { useFontSizes } from './useFontSize';
import ProtectedSection from './ProtectedSection';

const B = ({ children, ...props }: any) => (
  <Text
    {...props}
    style={[
      { fontWeight: 'bold', display: 'flex', flexDirection: 'row' },
      props.style,
    ]}>
    {children}
  </Text>
);

const S = ({ children, ...props }: any) => {
  const small = useFontSize(useFontSize.small);
  return (
    <Text
      {...props}
      style={[
        { fontSize: small, display: 'flex', flexDirection: 'row' },
        props.style,
      ]}>
      {children}
    </Text>
  );
};

const P = ({ children, ...props }: any) => {
  const paragraph = useFontSize(useFontSize.paragraph);
  return (
    <Text
      {...props}
      style={[
        { fontSize: paragraph, display: 'flex', flexDirection: 'row' },
        props.style,
      ]}>
      {children}
    </Text>
  );
};
const D = ({ children, fontSize = undefined, ...props }: any) => {
  return (
    <Text
      {...props}
      style={[
        { fontSize, display: 'flex', flexDirection: 'column' },
        props.style,
      ]}>
      {children}
    </Text>
  );
};

const NoWrap = ({ children, ...props }: any) => {
  return (
    <Text
      {...props}
      style={[
        {
          display: 'flex',
          flexDirection: 'column',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        props.style,
      ]}>
      {children}
    </Text>
  );
};

const App = () => {
  const styles = useStyles();
  const { width, height } = useWindowDimensions();
  const titleSize = useFontSize(useFontSize.title);
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.header}>
        <View style={styles.profilePicWrapper}>
          <Image
            style={styles.profilePic}
            source={require('./assets/adullah-coollah.png')} // Use the correct relative path
          />
        </View>
        <SnapTitle fontSize={titleSize}>Abdullah Faisal</SnapTitle>
        <Text style={styles.title}>
          <NoWrap>Managing Partner</NoWrap>
          <NoWrap>Operations Officer</NoWrap>
          <NoWrap>Founder @ Perspicacious.app</NoWrap>
        </Text>
      </View>

      <View style={styles.body}>
        <AnimatedSection style={styles.contactInfoSection}>
          <View style={styles.contactInfo}>
            <ImageBackground
              source={require('./assets/IMG_FBAA38A9B108-1.jpg')}
              imageStyle={{
                opacity: 0.2,
                ...Platform.select({
                  web: {
                    objectFit: 'cover',
                    filter: 'grayscale(50%) brightness(72%)',
                  },
                }),
              }}
              style={{
                width: width / 3,
                height: '100%',
                position: 'absolute',
                right: 0,
              }}>
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: 20,
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  zIndex: 999,
                  backgroundColor: '#FFFFFF',
                }}></View>
            </ImageBackground>
            <Text style={styles.contactTitle}>Contact Me:</Text>
            <View style={styles.contactBody}>
              <ProtectedSection protect={false}>
                <View style={styles.contactColumn}>
                  <Text style={styles.contactColumnTitle}>Phone & Email</Text>
                  <D onPress={() => Linking.openURL('tel:+13122847902')}>
                    <B>Mobile:</B>
                    <P style={{ marginLeft: 10 }}>+1.312.284.7902</P>
                  </D>
                  <D
                    onPress={() =>
                      Linking.openURL('mailto:Founder@Perspicacious.app')
                    }>
                    <B>Email:</B>
                    <P style={{ marginLeft: 10 }}>Founder@Perspicacious.app</P>
                  </D>
                </View>
                <View style={styles.contactColumn}>
                  <Text style={styles.contactColumnTitle}>
                    Links & Profiles
                  </Text>
                  <D
                    onPress={() =>
                      Linking.openURL('https://www.linkedin.com/in/enigmacoo/')
                    }>
                    <B>LinkedIn:</B>
                    <P style={{ marginLeft: 10 }}>enigmacoo</P>
                  </D>
                  <D
                    onPress={() => Linking.openURL('https://www.enigmakey.co')}>
                    <B>Company:</B>
                    <P style={{ marginLeft: 10 }}>enigmakey.co</P>
                  </D>
                  <D
                    onPress={() =>
                      Linking.openURL('https://perspicacious.app')
                    }>
                    <B>Company:</B>
                    <P style={{ marginLeft: 10 }}>perspicacious.app</P>
                  </D>
                  <D
                    onPress={() =>
                      Linking.openURL('https://perspicacious.ceo')
                    }>
                    <B>Personal:</B>
                    <P style={{ marginLeft: 10 }}>perspicacious.ceo</P>
                  </D>
                </View>
                <View style={styles.contactColumn}>
                  <View style={styles.contactColumnTitle}>
                    <B style={{ fontVariant: ['small-caps'] }}>
                      Current Location
                    </B>
                    <S style={{ marginLeft: 10 }}>(Okay with Remote)</S>
                  </View>
                  <B style={{ marginLeft: 10 }}>June 2020 - Current</B>
                  <P style={{ marginLeft: 10 }}>Chicago, IL</P>
                </View>
              </ProtectedSection>
            </View>
          </View>
        </AnimatedSection>

        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Summary</Text>
          <Text style={styles.sectionParagraph}>
            My objective is to advance my career as an operations manager within
            a technology-focused company, where I can contribute to realizing
            its full potential as a tech giant. I aim to utilize my skills in
            business communications, interpersonal interactions, team
            development, scheduling, business growth, and operations management
            to drive success. With over four years of experience in mobile, web,
            and native application development, I specialize in creating
            user-friendly and secure applications that enhance user experience.
            My expertise includes DevOps, hybrid and mobile solutions, and
            full-stack development. As an entrepreneur and software developer
            building my own product, and the Chief Operations Officer at Enigma
            Key Co., I am dedicated to empowering myself and my team to achieve
            our future goals.
          </Text>
          <Text style={styles.sectionParagraph}>
            <Text style={styles.bold}>Technical Skills:</Text>
          </Text>
          <Text style={styles.sectionParagraph}>
            <B>Languages & Platforms:</B> Python, TypeScript, JavaScript,
            Node.js, React, React Native, Java, Kotlin, Swift, Xcode, UIKit,
            PHP, HTML5.
          </Text>
          <Text style={styles.sectionParagraph}>
            <B>Extensive Expertise:</B> With a robust mastery of various technologies, I have successfully spearheaded and contributed to a multitude of projects.
             My deep technical knowledge has been pivotal in driving these projects to fruition, ensuring they meet and exceed expectations.
          </Text>
          <Text style={styles.sectionParagraph}>
            <Text style={styles.bold}>Commitment to Continuous Learning:</Text>
          </Text>
          <Text style={styles.sectionParagraph}>
          I am passionately devoted to advancing my skills in leadership, business acumen, and software engineering. 
          I continuously seek out and absorb knowledge about cutting-edge technologies and best industry practices. 
          This perpetual quest for learning allows me to broaden my expertise and stay ahead in my field, constantly evolving to meet new challenges.
          </Text>
          <Text style={styles.sectionParagraph}>
            <Text style={styles.bold}>Teamwork & Innovation:</Text>
          </Text>
          <Text style={styles.sectionParagraph}>
          I thrive in collaborative environments that emphasize innovation and quality. Working with a motivated team that shares these values is incredibly fulfilling for me.
          I find great joy in contributing to a group effort where creative ideas and high standards drive our collective success. This teamwork fosters a culture of continuous improvement and groundbreaking solutions.
          </Text>
          <Text style={styles.sectionParagraph}>
            <Text style={styles.bold}>Career Goal:</Text>
          </Text>
          <Text style={styles.sectionParagraph}>
          As a software developer and leader with extensive experience, my career goal is to drive impactful innovation by creating transformative products. 
          As the Chief Operations Officer at Enigma Key Co. and the CEO of Perspicacious, I am committed to leveraging my expertise in technology and operations to build solutions that make a significant difference in the world.
          </Text>
          <Text style={styles.sectionParagraph}>
            <Text style={styles.bold}>Leadership & Growth:</Text>
          </Text>
          <Text style={styles.sectionParagraph}>
          I aim to continue developing user-centric, secure applications and leading dynamic teams to reach their highest potential, contributing to the growth and success of forward-thinking technology companies. 
          </Text>
        </AnimatedSection>


        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Experience</Text>
          <ExperienceItem
            position="Founder & President"
            company="Perspicacious LLC"
            duration="2022 - Present"
            responsibilities={[
              " • Lead and manage all aspects of the company including staffing, project development, product marketing, and funding.",
              " • Oversee tax filings and regulatory compliance to ensure legal and financial integrity.",
              " • Develop strategic initiatives to drive growth and market presence."
            ].join('\n')}
          />
          <ExperienceItem
            position="Operations Officer"
            company="Enigma Key Co."
            duration="2021 - Present"
            responsibilities={[
              " • Organize and streamline business operations to align with company goals and growth trajectory.",
              " • Implement effective operational strategies to enhance productivity and efficiency.",
              " • Coordinate cross-functional teams to achieve business objectives."
            ].join('\n')}
          />
          <ExperienceItem
            position="Creative Solutions Engineer"
            company="Akadimia.Ai"
            duration="2023 - 2024"
            responsibilities={[
              " • Utilize critical thinking to drive innovative product development and user experience enhancement.",
              " • Collaborate with multidisciplinary teams to design and implement cutting-edge solutions.",
              " • Conduct market research to inform product features and improvements."
            ].join('\n')}
          />
          <ExperienceItem
            position="Managing Partner"
            company="T-Mobile Franchise"
            duration="2023 - Present"
            responsibilities={[
              " • Oversee daily operations, including staff management, inventory control, and customer service.",
              " • Develop and implement strategies to optimize business performance and profitability.",
              " • Ensure compliance with franchise standards and operational guidelines."
            ].join('\n')}
          />
          <ExperienceItem
            position="General Manager"
            company="Smokers’ Point"
            duration="2022 - 2023"
            responsibilities={[
              " • Assist in managing operations, staff, and inventory to ensure smooth business functioning.",
              " • Implement operational policies and procedures to enhance efficiency and customer satisfaction.",
              " • Coordinate with suppliers and vendors to maintain stock levels and product quality."
            ].join('\n')}
          />
          <ExperienceItem
            position="Sales Associate"
            company="Smokers’ Point"
            duration="2021 - 2022"
            responsibilities={[
              " • Provide exceptional customer service to enhance customer experience and retention.",
              " • Handle cash deposits and manage inventory to support store operations.",
              " • Assist in sales activities and promotional events to drive revenue growth."
            ].join('\n')}
          />
          
        </AnimatedSection>

        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Top Skills</Text>
          <Text style={styles.sectionParagraph}>
            TypeScript, JavaScript, Node.js, React, React Native, Swift, Xcode,
            Business Development, Operations Management, Jira Project Management,
            AI interfacing (GPT3.5/4), Prototypes / Wireframes, Figma / Graphic Design


          </Text>
          <Text style={styles.sectionTitle}>Other Skills</Text>
          <View style={styles.skillsGrid}>
            <Text style={styles.skillItem}>✓ Project Proposals </Text>
            <Text style={styles.skillItem}>✓ Direct Marketing</Text>
            <Text style={styles.skillItem}>✓ Mobile App Back-End</Text>
            <Text style={styles.skillItem}>✓ Google Cloud Platform</Text>
            <Text style={styles.skillItem}>✓ Image Manipulation</Text>
            <Text style={styles.skillItem}>✓ Transitions and Animations</Text>
            <Text style={styles.skillItem}>✓ Native iOS</Text>
            <Text style={styles.skillItem}>✓ Github Enterprise</Text>
            <Text style={styles.skillItem}>✓ UIKit</Text>
            <Text style={styles.skillItem}>✓ Encryption</Text>
            <Text style={styles.skillItem}>✓ Hybrid Mobile Apps</Text>
            <Text style={styles.skillItem}>✓ Angular</Text>
            <Text style={styles.skillItem}>✓ Ionic</Text>
            <Text style={styles.skillItem}>✓ Native Android</Text>
          </View>
          <Text style={styles.sectionTitle}>Spoken Languages</Text>
          <Text style={styles.sectionParagraph}>
            English (Native or Bilingual), Urdu (Native or Bilingual), Spanish (Basic level)
            , Punjabi (Native or Bilingual), Arabic (Basic level)

          </Text>
        </AnimatedSection>

        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Education</Text>
          <Text style={styles.sectionParagraph}>Bachelor of Science: Information Technology</Text>
          <Text style={styles.sectionParagraph}>
            DePaul University, 2020-2022
          </Text>
          <Text style={styles.sectionParagraph}>University of Illinos Chicago, 2018-2020</Text>
        </AnimatedSection>

        {/* COMMENTED OUT FOR NOW
        <AnimatedSection style={styles.section}>
          <Text style={styles.sectionTitle}>Projects</Text>
          <Text style={styles.sectionParagraph}>Project 1: Description of project 1</Text>
          <Text style={styles.sectionParagraph}>Project 2: Description of project 2</Text>
        </AnimatedSection> */}
      </View>
    </ScrollView>
  );
};

const SnapTitle = ({
  fontSize,
  children,
}: React.PropsWithChildren<{ fontSize: number }>) => {
  const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const snap = 8;
  const fullDeps = React.useMemo(
    () => [fontSize, width, height].filter(Boolean),
    [fontSize, width, height],
  );
  const adjustedValue = React.useRef(fontSize ?? 0);
  const calcNewValue = React.useCallback(() => {
    const newValue = Math.round(fontSize * ((width * 1.125) / height));
    adjustedValue.current = Math.round(newValue / snap) * snap;
  }, fullDeps);
  React.useEffect(() => {
    calcNewValue();
  }, fullDeps);
  calcNewValue();
  return (
    <Text style={[styles.name, { fontSize: adjustedValue.current }]}>
      {children}
    </Text>
  );
};

const useStyles = () => {
  const { width, height } = useWindowDimensions();
  const isPortrait = height > width * 1.125;

  const { title, subtitle, heading1, heading2, paragraph } = useFontSizes();

  return StyleSheet.create({
    container: {
      padding: 20,
      backgroundColor: '#f5f5f5',
    },
    header: {
      backgroundColor: '#007ACC',
      padding: 20,
      alignItems: 'center',
      borderRadius: 10,
      marginBottom: 20,
      zIndex: 999,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 2,
      flexDirection: isPortrait ? 'column' : 'row',
      justifyContent: 'center',
      overflow: 'hidden',
      alignContent: 'center',
    },
    body: {
      zIndex: 1,
      marginHorizontal: 20,
    },
    profilePicWrapper: {
      borderRadius: title * 3,
      width: title * 6,
      height: title * 6,
      marginBottom: 10,
      overflow: 'hidden',
    },
    profilePic: {
      borderRadius: title * 3,
      width: title * 6,
      height: title * 6,
      resizeMode: 'cover',
    },
    name: {
      fontSize: title,
      fontWeight: 'bold',
      color: '#fff',
      textAlign: 'center',
      marginHorizontal: 24,
      flexWrap: 'nowrap',
      flexDirection: 'row',
      flex: 1,
    },
    title: {
      fontSize: subtitle,
      fontStyle: 'italic',
      color: '#fff',
      textAlign: 'center',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    contactTitle: {
      fontSize: heading1,
      fontWeight: 'bold',
      marginVertical: 10,
      marginTop: 20,
      paddingHorizontal: 15,
      flexDirection: 'row',
    },
    contactBody: {
      paddingHorizontal: 10,
      marginBottom: 20,
      fontSize: paragraph,
      flexDirection: isPortrait ? 'column' : 'row',
      justifyContent: 'space-between',
    },
    contactInfo: {
      textAlign: 'left',
      marginBottom: 20,
      backgroundColor: '#fff',
      borderRadius: 10,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 2,
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    contactInfoSection: {
      marginBottom: 20,
    },
    contactColumn: {
      flex: 1,
      paddingHorizontal: 10,
      textAlign: 'center',
      flexDirection: 'column',
      marginVertical: 10,
      width: (width / 3) * 2 - 20,
    },
    contactColumnTitle: {
      fontWeight: 'bold',
      marginVertical: 10,
      flexDirection: 'column',
      fontVariant: ['small-caps'],
      fontSize: heading2,
    },
    section: {
      backgroundColor: '#fff',
      borderRadius: 10,
      padding: 20,
      marginBottom: 20,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 2,
    },
    sectionTitle: {
      fontSize: heading1,
      fontWeight: 'bold',
      marginBottom: 10,
      marginLeft: -5,
    },
    sectionParagraph: {
      marginBottom: 10,
      fontSize: paragraph,
    },
    textJustify: {
      textAlign: 'justify',
    },
    bold: {
      fontWeight: 'bold',
    },
    skillsGrid: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    skillItem: {
      width: '45%',
      marginVertical: 5,
    },
  });
};

export default App;
