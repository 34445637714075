import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const ExperienceItem = ({
  position,
  company,
  duration,
  location,
  responsibilities,
}: any) => {
  return (
    <View style={styles.experienceItem}>
      <Text style={styles.position}>{position}</Text>
      <Text style={styles.company}>{company}</Text>
      <Text style={styles.duration}>{duration}</Text>
      <Text style={styles.location}>{location}</Text>
      <Text style={styles.responsibilities}>{responsibilities}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  experienceItem: {
    marginBottom: 20,
  },
  position: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  company: {
    fontSize: 16,
    fontStyle: 'italic',
  },
  duration: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 5,
  },
  location: {
    fontSize: 14,
    fontStyle: 'italic',
    marginTop: 5,
  },
  responsibilities: {
    fontSize: 14,
    marginTop: 5,
  },
});

export default ExperienceItem;
